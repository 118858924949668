import { Grid2 } from '@mui/material';
import { FormEvent, useCallback, useContext, useMemo, useState } from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { AptlyOrganization } from '@aptly-as/types';
import ApiError from '../../../components/ApiError.js';
import useGetApiUrl from '../../../hooks/useGetApiUrl';
import simpleRequest from '../../../libraries/fetch/simpleRequest.js';
import Card, { CardContent, CardHeader } from '../../../mui/Card';
import { ButtonWithIcon } from '../../../mui/Button';
import { API_URL } from '../../../env';
import { Checkbox } from '../../../mui/Input.js';
import DropDocuments from '../../Document/DropDocuments';
import i18n from '../../../libraries/i18n';
import { OrganizationContext } from '../OrganizationContext';

export type ContractInfoProps = {
  data: AptlyOrganization;
};

const linkProps = (props: any): any => ({
  component: 'a',
  target: '_blank',
  variant: 'outlined',
  color: 'primary',
  rel: 'noreferrer noopener',
  ...props,
});

export default function ContractInfo({ data }: ContractInfoProps) {
  const path = useGetApiUrl('organization', '/contract');

  const content = useMemo(() => {
    if (!data.contractSign || !data.contractSign.document) {
      return <UploadDocument data={data} />;
    }

    if (data.contractSign.signed) {
      return <Download path={path} />;
    }

    return (
      <Grid2 container direction="column" spacing={2}>
        <Download path={path} />
        <Confirm path={path} />
      </Grid2>
    );
  }, [data, path]);

  return (
    <Card key="contract">
      <CardHeader avatar={<ReceiptIcon />} title="Databehandleravtale" />
      <CardContent>{content}</CardContent>
    </Card>
  );
}

function Download({ path }: { path: string }) {
  return (
    <Grid2>
      <ButtonWithIcon
        startIcon={<CloudDownloadOutlinedIcon />}
        {...linkProps({ download: true })}
        href={`${API_URL}/${path}`}
        color="secondary"
      >
        {i18n.t('actions.downloadDataContract')}
      </ButtonWithIcon>
    </Grid2>
  );
}

function Confirm({ path }: { path: string }) {
  const org = useContext(OrganizationContext);
  const [error, setError] = useState<any>(null);
  const handleOnConfirm = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      simpleRequest({
        endpoint: `${path}/confirm`,
        onError: setError,
        onRequestDone: () => {
          org.setData({
            contractSign: {
              ...org.data.contractSign!,
              signed: true,
            },
          });
        },
      });
    },
    [path, org]
  );

  return (
    <Grid2>
      <form onSubmit={handleOnConfirm}>
        <Checkbox required label={i18n.t('paragraphs.readAndAcceptedContract')} />
        <ButtonWithIcon type="submit" variant="contained" startIcon={<EditOutlinedIcon />}>
          {i18n.t('actions.confirmDataContract')}
        </ButtonWithIcon>
        {error && <ApiError error={error} />}
      </form>
    </Grid2>
  );
}

function UploadDocument({ data }: ContractInfoProps) {
  const { setData } = useContext(OrganizationContext);
  const path = useGetApiUrl('organization', '/contract');

  const handleOneDrop = useCallback(
    (files: any) => {
      setData({
        contractSign: {
          ...(data.contractSign || { hasReceivedFile: false }),
          document: files[0]._id,
        } as any,
      });
    },
    [data.contractSign, setData]
  );

  return <DropDocuments endpoint={path} onDrop={handleOneDrop} simple path="/" />;
}
