import SendIcon from '@mui/icons-material/Send';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { AptlyFieldType, AptlyInquiry, AptlyUnit } from '@aptly-as/types';
import { IUseFieldRet } from '@ewb/reach-react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlined';
import { unitStatusOptions } from '../../components/simpleCruds/unit/CrudUnitEdit';
import useGetApiUrl, { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import Select from '../../mui/Select';
import Grid from '../../mui/Grid';
import Typography from '../../mui/Typography';
import Chip from '../../mui/Chip';
import Button from '../../mui/Button';
import { useSimpleCrudFields } from '../../components/simple/useSimpleCrud';
import inquirySchema, { IInquirySchema } from './inquiry.schema';
import { SimpleCrudContent, SimpleCrudForm } from '../../components/simple/SimpleCrud';
import ApiError, { IApiError } from '../../components/ApiError';
import {
  ICrudFieldCustomGroupField,
  ICrudFieldData,
  ICrudSchema,
} from '../../components/crud/utils/crud.utils';
import usePaginatedSearch from '../../libraries/reach/usePaginatedSearch';

interface CrudInquiryBroadcastProps {
  onClose?: () => void;
  onCreate: () => void;
}

interface BroadcastMessage extends IInquirySchema {
  ids: string[];
}

export default function InquiryBroadcast({ onClose, onCreate }: CrudInquiryBroadcastProps) {
  const path = useGetApiUrl('project', '/inquiries/broadcast');
  const [crud, fields] = useSimpleCrudFields<BroadcastMessage, keyof BroadcastMessage>(
    path,
    {},
    {
      ids: {
        type: AptlyFieldType.Custom,
        defaultValue: [],
        label: i18n.t('singles.units'),
        customRender: (crud: any, field: any) => <CustomUnitSearch crud={crud} field={field} />,
      },
      ...inquirySchema(),
    } as ICrudSchema<BroadcastMessage>,
    ['ids', 'status', 'title', 'message', 'files'],
    {
      reachOptions: {
        type: 'multipart/form-data',
        fileKeys: ['files'],
      },
      dontSetStateOnPost: true,
    }
  );

  const handleOnSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const res = await crud.save();
      if (res) {
        onCreate();
        if (onClose) {
          onClose();
        }
      }
    },
    [crud, onCreate, onClose]
  );

  return (
    <SimpleCrudForm
      title={i18n.t('actions.sendToMultiple')}
      onClose={onClose}
      actionButtonProps={{
        disabled: crud.state.busy,
        actionLabel: i18n.t('actions.send'),
        actionIcon: <SendIcon />,
      }}
      onSubmit={handleOnSubmit}
    >
      <SimpleCrudContent crud={crud} schema={fields} />
      {crud.state.error && <ApiError error={crud.state.error} />}
    </SimpleCrudForm>
  );
}

function CustomUnitSearch({
  crud,
  field,
}: {
  crud: IUseFieldRet<BroadcastMessage, IApiError, ICrudFieldData<AptlyInquiry>, AptlyInquiry[]>;
  field: ICrudFieldCustomGroupField<BroadcastMessage, 'ids'>;
}) {
  const url = useApiUrl(SlugLevel.Project, 'units');
  const [, units, error, , , actions] = usePaginatedSearch<AptlyUnit>(
    url,
    useMemo(
      () => ({
        limit: 999,
        query: { select: '_id,name,users,invites', sort: 'name', archived: false },
      }),
      []
    )
  );
  const ids = field.value;

  const handleOnStatusSelect = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const status = e.target.value;
      return actions.search({ status });
    },
    [actions]
  );

  const setActive = useCallback(
    (active: boolean, unit: AptlyUnit) => () => {
      const newIds = active ? ids.filter((x) => x !== unit._id) : [...ids, unit._id];
      crud.setField('ids')(newIds);
    },
    [crud, ids]
  );

  const isActive = useCallback(
    (x: AptlyUnit): any =>
      ids.some((y) => x._id === y)
        ? {
            icon: <CheckBoxIcon />,
            onClick: setActive(true, x),
            variant: 'outlined',
          }
        : {
            icon: <CheckBoxOutlineBlankIcon />,
            onClick: setActive(false, x),
            variant: 'outlined',
          },
    [ids, setActive]
  );

  if (!units) return null;

  const unitsList = units.filter((x) => x.users?.length > 0 || x.invites.length > 0);

  return (
    <Grid container spacing={2} direction="column">
      <Grid container item>
        <Typography variant="subtitle1">{i18n.t('singles.filter')}</Typography>
        <Select
          fullWidth
          onChange={handleOnStatusSelect}
          options={[{ value: '', label: i18n.t('singles.all') }, ...(unitStatusOptions() as any[])]}
          label={i18n.t('singles.status')}
        />
      </Grid>
      <Grid container item justifyContent="space-between">
        <Grid item>
          <Typography variant="subtitle2">
            {i18n.t('singles.units')} ({ids.length} / {unitsList.length}):
          </Typography>
        </Grid>
        <Grid container item xs={6} spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => crud.setField('ids')([])}
              startIcon={<CheckBoxOutlineBlankIcon />}
            >
              {i18n.t('statuses.nothing')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => crud.setField('ids')(unitsList.map((x) => x._id))}
              startIcon={<CheckBoxIcon />}
            >
              {i18n.t('singles.all')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        {error && <ApiError error={error} />}
        {unitsList.length === 0
          ? null
          : unitsList.map((x) => (
              <Grid item key={x._id}>
                <Chip {...isActive(x)} label={x.name} />
              </Grid>
            ))}
      </Grid>
    </Grid>
  );
}
