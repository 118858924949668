import {
  AptlyApp,
  AptlyAppInjectionType,
  AptlyAppMenu,
  AptlyAppSetting,
  AptlyAppWebhook,
  AptlyFieldType,
  AptlyOrganizationInjection,
  AptlyProjectInjection,
  AptlyScopes,
  AptlyUnitInjection,
  AptlyWebhookType,
} from '@aptly-as/types';
import {
  schemaDescription,
  schemaMediaIcon,
  schemaMediaLogo,
  schemaName,
} from '../../components/crud/schema/fields.schema';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import i18n from '../../libraries/i18n';

const toOption = (value: string) => ({ value, label: value });

export const appSchemaFields: (keyof AptlyApp)[] = [
  'name',
  'cost',
  'description',
  'logo',
  'slug',
  'installUrl',
  'redirectUris',
];

export const appSchema = (): ICrudSchema<AptlyApp> => ({
  name: schemaName({
    autoFocus: true,
  }),
  cost: {
    type: AptlyFieldType.Number,
    defaultValue: 0,
    label: i18n.t('singles.cost'),
  },
  slug: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('singles.slug'),
  },
  description: schemaDescription({}),
  installUrl: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('singles.installUrl'),
    required: true,
  },
  redirectUris: {
    type: AptlyFieldType.StringArray,
    defaultValue: [],
    label: i18n.t('singles.redirectUris'),
  },
  logo: schemaMediaLogo({}),
});

export const appSettingsSchemaFields: (keyof AptlyAppSetting)[] = [
  'type',
  'key',
  'label',
  'defaultValue',
  'required',
];
export const appSettingsSchema = (): ICrudSchema<AptlyAppSetting> => ({
  type: {
    type: AptlyFieldType.Select,
    defaultValue: AptlyFieldType.Text,
    label: i18n.t('singles.type'),
    options: Object.values(AptlyFieldType).map(toOption),
  },
  key: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('singles.key'),
  },
  label: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('singles.label'),
  },
  required: {
    type: AptlyFieldType.Switch,
    defaultValue: false,
    label: i18n.t('singles.required'),
  },
  defaultValue: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('singles.defaultValue'),
  },
});

export const appMenuSchemaFields: (keyof AptlyAppMenu)[] = [
  'type',
  'label',
  'icon',
  'model',
  'menu',
  'src',
  'sandbox',
];
const menuInjections = [
  ...Object.values(AptlyOrganizationInjection).map((x) => (x ? `organization-${x}` : 'organization')),
  ...Object.values(AptlyProjectInjection).map((x) => (x ? `project-${x}` : 'project')),
  ...Object.values(AptlyUnitInjection).map((x) => (x ? `unit-${x}` : 'unit')),
].map(toOption);
export const appMenuSchema = (): ICrudSchema<AptlyAppMenu> => ({
  type: {
    type: AptlyFieldType.Select,
    defaultValue: AptlyAppInjectionType.Iframe,
    label: i18n.t('singles.type'),
    options: [],
  },
  label: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('singles.label'),
  },
  icon: schemaMediaIcon(),
  src: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('singles.src'),
  },
  sandbox: {
    type: AptlyFieldType.Select,
    defaultValue: [],
    label: i18n.t('singles.sandbox'),
    options: [
      { value: 'allow-same-origin', label: 'allow-same-origin' },
      { value: 'allow-forms', label: 'allow-forms' },
      { value: 'allow-popups', label: 'allow-popups' },
      { value: 'allow-modals', label: 'allow-modals' },
    ],
    multiple: true,
  },
  menu: {
    type: AptlyFieldType.Select,
    defaultValue: [],
    label: i18n.t('singles.menu'),
    options: menuInjections,
    multiple: true,
  },
  model: {
    type: AptlyFieldType.Select,
    label: i18n.t('singles.model'),
    defaultValue: AptlyScopes.Organization,
    options: Object.values(AptlyScopes).map(toOption),
  },
});

export const appWebhookSchemaFields: (keyof AptlyAppWebhook)[] = ['types', 'url'];
export const appWebhookSchema = (): ICrudSchema<AptlyAppWebhook> => ({
  types: {
    type: AptlyFieldType.Select,
    defaultValue: [],
    label: i18n.t('singles.types'),
    options: Object.values(AptlyWebhookType).map(toOption),
    multiple: true,
    required: true,
  },
  url: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('singles.url'),
    required: true,
  },
});
